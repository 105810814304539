'use client'
import { useState } from 'react'
import { SVGFaqClose, SVGFaqOpen } from '@/components/svg'
import style from "./style.module.scss";

const AccordionCloseItem = ({ title, description, isOpen, onClick }) => {
  return (
    <>
      <li>
        <button aria-expanded={isOpen} onClick={onClick} >
          <h6 dangerouslySetInnerHTML={{ __html: title }} />
          <div className={`${style.svg_container}`}>
            {isOpen ? <SVGFaqClose /> : <SVGFaqOpen />}
          </div>
        </button>
        <div className={`text-left overflow-hidden transition-all ease-in-out;
        ${isOpen
            ? 'duration-[1700ms] max-h-screen'
            : 'duration-[900ms] max-h-0'}
        `}
        >
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </li>

    </>
  )
}

export default AccordionCloseItem