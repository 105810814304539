'use client'
import React, { useMemo } from 'react'
import Image from 'next/image';
import useScreenResolution from '@/hooks/useScreenResolution';
import useResponsiveAnimation from '@/hooks/useResponsiveAnimation';


const BarinasAnimations = {
  "desktop": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/barinas_selected.svg",
    "base_active": "scale-[100%] transition-all ease-in-out duration-[700ms]",
    "active_primary": "scale-[90%] translate-x-[10%] -translate-y-[130%]",
    "active_secondary": "!scale-[100%] !translate-x-[10%] !-translate-y-[130%]",
    "base_hover": "transition-all ease-in-out delay-[250ms] duration-[400ms]",
    "hover_primary": "scale-[100%] !translate-x-[10%] !-translate-y-[130%]",
    "hover_secondary": "!scale-[100%] !translate-x-[10%] !-translate-y-[130%] delay-[450ms] duration-[300ms]"
  },
  "tablet": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/barinas_selected.svg",
    "portrait": {
      "base_active": "scale-[60%] -translate-x-[3%] -translate-y-[115%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] -translate-x-[3%] -translate-y-[115%]",
      "active_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[115%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] -translate-x-[3%] -translate-y-[115%]",
      "hover_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[115%] duration-[300ms] "
    },
    "landscape": {
      "base_active": "scale-[60%] translate-x-[3%] -translate-y-[115%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] translate-x-[3%] -translate-y-[115%]",
      "active_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[115%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] translate-x-[3%] -translate-y-[115%]",
      "hover_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[115%] duration-[300ms] "
    }
  },
  "mobile": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/barinas_selected.svg",
    "portrait": {
      "base_active": "scale-[50%] -translate-x-[20%] -translate-y-[95%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[60%] -translate-x-[20%] -translate-y-[95%]",
      "active_secondary": "!scale-[60%] !-translate-x-[20%] !-translate-y-[95%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[60%] -translate-x-[20%] -translate-y-[95%]",
      "hover_secondary": "!scale-[60%] !-translate-x-[20%] !-translate-y-[95%] duration-[300ms] "
    },
    "landscape": {
      "base_active": "scale-[60%] -translate-x-[7%] -translate-y-[115%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[70%] -translate-x-[7%] -translate-y-[115%]",
      "active_secondary": "!scale-[70%] !-translate-x-[7%] !-translate-y-[115%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[70%] -translate-x-[7%] -translate-y-[115%]",
      "hover_secondary": "!scale-[70%] !-translate-x-[7%] !-translate-y-[115%] duration-[300ms] "
    },
  }
}

const CaracasAnimations = {
  "desktop": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/caracas_selected.svg",
    "base_active": "scale-[100%] translate-x-[10%] -translate-y-[130%] transition-all ease-in-out duration-[700ms]",
    "active_primary": "scale-[300%] -translate-x-[2%] -translate-y-[93%]",
    "active_secondary": "!scale-[340%] !-translate-x-[2%] !-translate-y-[93%]",
    "base_hover": "transition-all ease-in-out delay-[500ms] duration-[500ms]",
    "hover_primary": "scale-[340%] !-translate-x-[2%] !-translate-y-[93%]",
    "hover_secondary": "!scale-[340%] !-translate-x-[2%] !-translate-y-[93%]"
  },
  "tablet": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/caracas_selected.svg",
    "portrait": {
      "base_active": "scale-[200%] -translate-x-[20%] -translate-y-[35%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[320%] !-translate-x-[20%] !-translate-y-[75%]",
      "active_secondary": "!scale-[320%] !-translate-x-[20%] !-translate-y-[75%]",
      "base_hover": "transition-all ease-in-out",
      "hover_primary": "scale-[320%] !-translate-x-[20%] !-translate-y-[75%]",
      "hover_secondary": "!scale-[320%] !-translate-x-[20%] !-translate-y-[75%] delay-[50ms] duration-[300ms]"
    },
    "landscape": {
      "base_active": "scale-[200%] -translate-x-[15%] -translate-y-[35%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[320%] !-translate-x-[10%] !-translate-y-[75%]",
      "active_secondary": "!scale-[320%] !-translate-x-[10%] !-translate-y-[75%]",
      "base_hover": "transition-all ease-in-out",
      "hover_primary": "scale-[320%] !-translate-x-[10%] !-translate-y-[75%]",
      "hover_secondary": "!scale-[320%] !-translate-x-[10%] !-translate-y-[75%] delay-[50ms] duration-[300ms]"
    }
  },
  "mobile": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/caracas_selected.svg",
    "portrait": {
      "base_active": "scale-[100%] -translate-x-[35%] -translate-y-[35%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[290%] !-translate-x-[30%] !-translate-y-[125%]",
      "active_secondary": "!scale-[290%] !-translate-x-[30%] !-translate-y-[125%]",
      "base_hover": "transition-all ease-in-out",
      "hover_primary": "scale-[290%] !-translate-x-[30%] !-translate-y-[125%]",
      "hover_secondary": "!scale-[290%] !-translate-x-[30%] !-translate-y-[125%] delay-[150ms] duration-[300ms]"
    },
    "landscape": {
      "base_active": "scale-[100%] -translate-x-[25%] -translate-y-[35%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[290%] !-translate-x-[20%] !-translate-y-[125%]",
      "active_secondary": "!scale-[290%] !-translate-x-[20%] !-translate-y-[125%]",
      "base_hover": "transition-all ease-in-out",
      "hover_primary": "scale-[290%] !-translate-x-[20%] !-translate-y-[125%]",
      "hover_secondary": "!scale-[290%] !-translate-x-[20%] !-translate-y-[125%] delay-[150ms] duration-[300ms]"
    }
  }
}

const LaraAnimations = {
  "desktop": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/lara_selected.svg",
    "base_active": "scale-[340%] -translate-x-[2%] -translate-y-[93%] transition-all ease-in-out duration-[700ms]",
    "active_primary": "scale-[340%] !translate-x-[10%] -translate-y-[93%]",
    "active_secondary": "!scale-[110%] !translate-x-[15%] !-translate-y-[50%]",
    "base_hover": "transition-all ease-in-out delay-[600ms] duration-[500ms]",
    "hover_primary": "scale-[110%] !translate-x-[15%] !-translate-y-[50%]",
    "hover_secondary": "!scale-[110%] !translate-x-[15%] !-translate-y-[50%] delay-[700ms] duration-[300ms]"
  },
  "tablet": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/lara_selected.svg",
    "portrait": {
      "base_active": "scale-[60%] -translate-x-[3%] -translate-y-[45%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] -translate-x-[3%] -translate-y-[45%]",
      "active_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[45%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] -translate-x-[3%] -translate-y-[45%]",
      "hover_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[45%] duration-[300ms] "
    },
    "landscape": {
      "base_active": "scale-[60%] translate-x-[3%] -translate-y-[45%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] translate-x-[3%] -translate-y-[45%]",
      "active_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[45%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] translate-x-[3%] -translate-y-[45%]",
      "hover_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[45%] duration-[300ms] "
    }
  },
  "mobile": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/lara_selected.svg",
    "portrait": {
      "base_active": "scale-[60%] -translate-x-[17%] -translate-y-[45%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[80%] -translate-x-[17%] -translate-y-[45%]",
      "active_secondary": "!scale-[80%] !-translate-x-[17%] !-translate-y-[45%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[80%] -translate-x-[17%] -translate-y-[45%]",
      "hover_secondary": "!scale-[80%] !-translate-x-[17%] !-translate-y-[45%] duration-[400ms] "
    },
    "landscape": {
      "base_active": "scale-[60%] -translate-x-[7%] -translate-y-[45%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[80%] -translate-x-[7%] -translate-y-[45%]",
      "active_secondary": "!scale-[80%] !-translate-x-[7%] !-translate-y-[45%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[80%] -translate-x-[7%] -translate-y-[45%]",
      "hover_secondary": "!scale-[80%] !-translate-x-[7%] !-translate-y-[45%] duration-[400ms] "
    }
  }
}

const PortuguesaAnimations = {
  "desktop": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/portuguesa_selected.svg",
    "base_active": "scale-[120%] translate-x-[15%] -translate-y-[50%] transition-all ease-in-out duration-[700ms]",
    "active_primary": "scale-[90%] !translate-x-[5%] -translate-y-[90%]",
    "active_secondary": "!scale-[100%] !translate-x-[10%] !-translate-y-[90%]",
    "base_hover": "transition-all ease-in-out duration-[500ms]",
    "hover_primary": "scale-[100%] !translate-x-[5%] !-translate-y-[90%]",
    "hover_secondary": "!scale-[100%] !-translate-x-[5%] !-translate-y-[90%] delay-[550ms] duration-[300ms]"
  },
  "tablet": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/portuguesa_selected.svg",
    "portrait": {
      "base_active": "scale-[70%] -translate-x-[3%] -translate-y-[85%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] -translate-x-[3%] -translate-y-[85%]",
      "active_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[85%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] -translate-x-[3%] -translate-y-[85%]",
      "hover_secondary": "!scale-[90%] !-translate-x-[3%] !-translate-y-[85%] duration-[300ms] "
    },
    "landscape": {
      "base_active": "scale-[70%] translate-x-[3%] -translate-y-[85%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] translate-x-[3%] -translate-y-[85%]",
      "active_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[85%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] translate-x-[3%] -translate-y-[85%]",
      "hover_secondary": "!scale-[90%] !translate-x-[3%] !-translate-y-[85%] duration-[300ms] "
    }
  },
  "mobile": {
    "img": "/images/content/contactus/mapa_vzla.svg",
    "img_selected": "/images/content/contactus/portuguesa_selected.svg",
    "portrait": {
      "base_active": "scale-[60%] -translate-x-[20%] -translate-y-[100%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[80%] -translate-x-[20%] -translate-y-[100%]",
      "active_secondary": "!scale-[80%] !-translate-x-[20%] !-translate-y-[100%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[80%] -translate-x-[20%] -translate-y-[100%]",
      "hover_secondary": "!scale-[80%] !-translate-x-[20%] !-translate-y-[100%] duration-[400ms] "
    },
    "landscape": {
      "base_active": "scale-[60%] -translate-x-[10%] -translate-y-[113%] transition-all ease-in-out duration-[500ms]",
      "active_primary": "scale-[90%] -translate-x-[10%] -translate-y-[113%]",
      "active_secondary": "!scale-[90%] !-translate-x-[10%] !-translate-y-[113%]",
      "base_hover": "transition-all ease-in-out ",
      "hover_primary": "scale-[90%] -translate-x-[10%] -translate-y-[113%]",
      "hover_secondary": "!scale-[90%] !-translate-x-[10%] !-translate-y-[113%] duration-[400ms] "
    }
  }
}


const AnimateBanner = React.memo(({ id, name, showTabs }) => {

  const { deviceType, width } = useScreenResolution();
  const isMobile = (deviceType === 'desktop' || deviceType === undefined) ? false
    : true;

  const animationMap = useMemo(() => ({
    "Barinas": BarinasAnimations,
    "Caracas": CaracasAnimations,
    "Lara": LaraAnimations,
    "Portuguesa": PortuguesaAnimations
  }), []);


  const animations = useMemo(() => animationMap[name], [animationMap, name]);

  const { imgAnimate1, imgAnimate2, styleAnimate, objectAnimate } = useResponsiveAnimation(deviceType, isMobile, width, animations);


  return (
    <>
      {(styleAnimate !== null && objectAnimate !== undefined) && (
        <figure className={`group relative w-full bg-xana-blue  rounded-2xl overflow-hidden ${showTabs ? 'h-[220px]' : 'h-[180px]'}`} >
          <div className={`absolute inset-0 min-w-max !opacity-100
          ${styleAnimate?.base_active}
           ${imgAnimate1 && `!opacity-100 ${styleAnimate?.active_primary}`}
           ${imgAnimate2 && `delay-100 duration-300 ease-in-out ${styleAnimate?.active_secondary} `}
           `}>
            <Image
              alt="Image State"
              src={objectAnimate?.img}
              priority
              width={50}
              height={50}
              className={`relative z-10 object-cover object-top min-w-max`}
            />
          </div>
          <div className={`absolute inset-0 min-w-max opacity-0
           ${styleAnimate?.base_hover}
           ${imgAnimate1 && styleAnimate?.hover_primary}
           ${imgAnimate2 && `ease-in-out !opacity-100 ${styleAnimate?.hover_secondary}`}
           `}>
            <Image
              alt="Image State"
              src={objectAnimate?.img_selected}
              priority
              width={50}
              height={50}
              className={`relative z-20 object-cover object-top min-w-max`}
            />
          </div>
        </figure>
      )}

    </>

  )
});

AnimateBanner.displayName = 'AnimateBanner';

export default AnimateBanner;
