/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import React, { useMemo } from 'react'
import { AnimateBanner, Pharmacies } from '@/components/shared';
import style from "./style.module.scss";
import Image from 'next/image';

const TabsItem = React.memo(({ id, nombre, farmacias, active, showTabs }) => {

  const contentToShow = useMemo(() => {
    return active === `${active}` ? 'flex' : 'hidden';
  }, [active, id]);

  return (
    <div
      id={`tab${id}`}
      className={`${style.section_tabs_content} ${contentToShow}`}
    >
      {/* <Image
        src={`${process.env.NEXT_PUBLIC_RUTA_IMG}/gif/test.gif`}
        alt="Descripción del gif"
        width={500}
        height={300}
        unoptimized={false}
      /> */}

      <AnimateBanner id={id} name={nombre} showTabs={showTabs} />
      <Pharmacies data={farmacias} active={active} />
    </div>
  )
});

TabsItem.displayName = 'TabsItem';

export default TabsItem;