import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import style from "./style.module.scss";
import Image from 'next/image';

const NewsDetail = ({ data, category }) => {

  return (
    <>
      <div className={`${style.section_banner_detail} container_long !px-0 `}>
        <figure>
          <Image
            alt="Conten Image"
            src={data?.banner?.url}
            width={1000}
            height={740}
            sizes="100vw"
            priority
          />
        </figure>
      </div>

      <div className={`container_normal `}>
        <div className={`${style.blocksrenderer}`}>
          <BlocksRenderer
            content={data?.cuerpo}
          />
        </div>
      </div>
    </>
  )
}

export default NewsDetail