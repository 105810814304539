/* eslint-disable @next/next/no-img-element */
import { ContentLeftRightItem } from "@/components/shared";
import style from "./style.module.scss";

const ContentLeftRight = ({ data }) => {

  if (!data) { return null; }


  return (
    <div className={`${style.section_aboutus} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out `}>
      <div className={`${style.aboutus_content_container} `}>
        {data.map((item, idx) => (
          <ContentLeftRightItem key={item.id} idx={idx} length={data.length} {...item} />
        ))}
      </div>
    </div>
  )
}


export default ContentLeftRight