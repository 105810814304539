import { useState } from "react";
import style from "./style.module.scss";


const ButtonLink = ({ title, icon }) => {
  return (
    <button className={`${style.button_address_container}`}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: icon }} />
    </button>
  )
}


const ButtonNews = ({ title, icon, cssClass }) => {
  return (
    <button className={`group ${style.button_news_container} ${cssClass}`
    }>
      <span className={`group-hover:text-xana-txt-breadcrumb-blue `} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={`${style.icon_svg} group-hover:text-xana-txt-breadcrumb-blue `}
        dangerouslySetInnerHTML={{ __html: icon }} />
    </button >
  )
}

const ButtonFunc = ({ title, icon, onClick }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    try {
      setLoading(true);
      setTimeout(async () => {
        await onClick();
        setLoading(false);
      }, 700);

    } catch (error) {
      console.error('Error al ejecutar la acción:', error);
      setLoading(false);
    }
  };

  return (
    <button
      className={`group ${style.button_func_container}`}
      onClick={handleClick}
      disabled={loading}
    >

      {loading && (
        <div className={`${style.icon_svg} group-hover:text-xana-txt-breadcrumb-blue animate-spin `}
          dangerouslySetInnerHTML={{ __html: icon }} />
      )}

      <span className='group-hover:text-xana-txt-breadcrumb-blue' dangerouslySetInnerHTML={{ __html: title }} />


    </button>
  );
};




const Button = (props) => {
  const componentsSelected = {
    link_address: <ButtonLink {...props} />,
    link_news: <ButtonNews {...props} />,
    link_func: <ButtonFunc {...props} />,
  };
  return componentsSelected[props.type] || null;
}

export default Button