'use client'
import Image from 'next/image';
import Link from 'next/link';
import style from "./style.module.scss";

const NewsCard = ({ data, totalNews, cat, slug, type }) => {

  if (!data) { return null; }

  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };


  return (
    <div className={`group transition-all duration-500 ease-in-out transform hover:shadow-lg ${style.news_card}
    ${type !== 'list' && totalNews > 0 && totalNews < 3
        ? totalNews === 1
          ? 'h-full md:h-56 xl:h-72 flex-col md:flex-row'
          : 'h-full md:h-56 xl:h-72 flex-col lg:flex-row'
        : 'h-full md:h-auto flex-col '}

    ${type === 'list' && totalNews > 0 && 'h-full md:h-56 flex-col'}

      `}>
      <figure className={`overflow-hidden
      ${type !== 'list' && totalNews > 0 && totalNews < 3
          ? totalNews === 1
            ? 'w-full md:w-[50%] h-64 md:h-80'
            : 'w-full lg:w-[50%] h-64 lg:h-80'
          : 'w-full h-64 md:h-56 xl:h-64'
        }

      ${type === 'list' && totalNews > 0 && 'w-full h-64 md:h-56 xl:h-72'}
      `}>
        <Link
          href={`/blog/${slug}/${data?.slug}`}
          passHref
        >
          <Image
            alt="Image State"
            src={data?.banner?.url}
            width={10}
            height={10}
            sizes="100vw"
            className='transition-all duration-500 ease-in-out transform group-hover:scale-110'
          />
        </Link>
      </figure>

      <div className={` ${style.news_card_text_container}
        ${totalNews > 0 && totalNews < 3
          ? 'p-5 '
          : 'p-4 '}
      `}>
        <Link
          href={`/blog/${slug}`}
          passHref
        >
          <h4 dangerouslySetInnerHTML={{ __html: cat }} />
        </Link>

        <div className={`w-full h-auto`}>
          <Link
            href={`/blog/${slug}/${data?.slug}`}
            passHref >
            <h2 dangerouslySetInnerHTML={{ __html: data?.titulo }} />
            <p className={`
               ${type !== 'list' && totalNews > 0 && totalNews < 3
                ? 'line-clamp-3 lg:line-clamp-4 xl:line-clamp-4'
                : 'line-clamp-2 lg:line-clamp-3 xl:line-clamp-3'
              }

              ${type === 'list' && totalNews > 0 && 'line-clamp-3 lg:line-clamp-3 xl:line-clamp-4'}


          `} dangerouslySetInnerHTML={{ __html: data?.resumen }} />
          </Link>
        </div>

        <footer>
          <span>
            {new Date(data?.publishedAt).toLocaleDateString('es-ES', optionsDate)}
          </span>
        </footer>

      </div>
    </div >
  );
}

export default NewsCard