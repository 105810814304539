/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

const AnimateContent = ({ animationClass, children }) => {
  const ref = useRef();
  // const { ref: inViewRef, inView } = useInView();

  const { ref: inViewRef, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });


  useEffect(() => {
    if (inView && ref.current) {
      inViewRef(ref.current);
    }
  }, [inViewRef]);

  return (
    <div ref={inViewRef} className={`${inView && animationClass}`}>
      {children}
    </div>
  );
};

export default AnimateContent;
