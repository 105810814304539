'use client'
import style from "./style.module.scss";


const CTAText = ({ data }) => {
  if (!data) { return null; }

  return (
    <section className={`${style.section_cta} animate-fade`}>
      <h2 dangerouslySetInnerHTML={{ __html: data.titulo }} />
    </section>
  )
}

const CTASubscribe = ({ data }) => {
  return (
    <>CTASubscribe</>
  )
}


const CTA = (props) => {
  const componentsSelected = {
    text: <CTAText {...props} />,
    subscribe: <CTASubscribe {...props} />,
  };
  return componentsSelected[props.section] || null;
}

export default CTA