/* eslint-disable react-hooks/rules-of-hooks */
'use client'
import { NewsCard } from '@/components/shared';
import useBreakpointItemsToDelete from '@/hooks/useBreakpointItemsToDelete';

const NewsCategoryGrid = ({ data, cat, slug, col }) => {

  if (!data) { return null; }


  const itemsToShows = useBreakpointItemsToDelete(data, cat);
  const filteredData = itemsToShows;
  const settingGrids = {
    1: 'grid grid-cols-1 md:grid-cols-1 md mt-10 gap-6',
    2: 'grid grid-cols-1 md:grid-cols-2 mt-10 gap-6',
    3: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-10 gap-6',
    4: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 gap-6',
  }

  return (
    <article className={`${col ? settingGrids[col] : settingGrids[filteredData.length]}`}>
      {filteredData.map((item) => (
        <div key={item.id}>
          <NewsCard data={item} cat={cat} slug={slug} totalNews={filteredData.length} type={''} />
        </div>
      ))}
    </article >
  )
}

export default NewsCategoryGrid