import { SVGDot } from "@/components/svg";
import style from "./style.module.scss";

const optionsDate = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};


const TitleNormal = ({ title, subtitle, description }) => {
  if (!subtitle && !description) {
    return null;
  }
  /* animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out */
  return (
    <header className={`${style.title_normal} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out`}>
      <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </header>
  )
}


const TitleContent = ({ title, date, read, description }) => {

  /* animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out */
  return (
    <header className={`${style.title_content} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out`}>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <div className={`${style.subtitle_section}`}>
        <span dangerouslySetInnerHTML={{ __html: new Date(date).toLocaleDateString('es-ES', optionsDate) }} />
        <div className={`${style.svg_separator}`}>
          <SVGDot />
        </div>
        <span dangerouslySetInnerHTML={{ __html: `Lectura de ${read} minutos` }} />
      </div>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </header>
  )
}


const TitleBig = ({ title, subtitle, description }) => {
  return (
    <header className={`${style.title_big}`}>
      <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </header>
  )
}

const TitleLeft = ({ title, subtitle, description }) => {
  return (
    <header className={`${style.title_text_left}`}>
      <h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </header>
  )
}



const Title = (props) => {
  const componentsSelected = {
    normal: <TitleNormal {...props} />,
    content: <TitleContent {...props} />,
  };
  return componentsSelected[props.type] || null;
}

export default Title

