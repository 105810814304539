'use client'
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, PharmaciesItem } from '@/components/shared';
import style from "./style.module.scss";

const Pharmacies = ({ data, active }) => {
  const showInitial = 6;
  const [clientsSelected, setClientsSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingText, setIsLoadingText] = useState(false);

  const startIndex = page * showInitial;
  const showItems = data.length - startIndex
  const endIndex = startIndex + showItems;

  const clientsSelectedMemo = useMemo(() => data.slice(0, showInitial), [data]);
  const morePharmaciesText = useMemo(() => data.slice(startIndex, endIndex), [data, startIndex, endIndex]);

  const loadMoreFeatureText = useCallback(async () => {
    setIsLoading(true);
    setIsLoadingText(true)
    setClientsSelected(prev => [...prev, ...morePharmaciesText]);
    setPage(prev => prev + 1);
    setIsLoading(false);
  }, [morePharmaciesText]);

  useEffect(() => {
    setClientsSelected(clientsSelectedMemo);
    setPage(1);
    setIsLoading(false);
  }, [active, clientsSelectedMemo]);

  const seeLessFeatureText = useCallback(() => {
    setClientsSelected(clientsSelectedMemo);
    setPage(1);
    setIsLoadingText(false)
    setIsLoading(false);
  }, [clientsSelectedMemo]);

  return (
    <div className={`${style.section_text_address}   `}>
      <div className={`${style.address_grid_container}`}>
        {clientsSelected.map((item) => (
          <PharmaciesItem key={item.id} {...item} />
        ))}
      </div>

      {showInitial < data.length && (
        <Button
          type={'link_func'}
          title={clientsSelected.length === data.length ? 'Mostrar menos' : 'Ver todas las tiendas'}
          icon={'<svg fill=\"currentColor\" viewBox=\"0 0 24 24\"> <circle class=\"opacity-15\" cx=\"12\" cy=\"12\" r=\"10\" stroke=\"currentColor\" stroke-width=\"4\"></circle> <path class=\"opacity-75\" fill=\"currentColor\" d=\"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z\"> </path> </svg>'}
          onClick={clientsSelected.length === data.length ? seeLessFeatureText : loadMoreFeatureText}
          isloanding={isLoading}
        />
      )}
    </div>
  );
};

export default Pharmacies;
