import Image from 'next/image';
import style from "./style.module.scss";

const FeatureIMG = ({ img, title, description }) => {
  return (
    <div className={`${style.features_container}`}>
      <Image
        alt="Background Image"
        src={img}
        width={50}
        height={50}
        sizes="100vw"
        className={`${style.img_image_container}`}
      />
      <section>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </section>
    </div>
  )
}

const FeatureICON_SVG = ({ img, title, description }) => {
  return (
    <div className={`${style.features_container}`}>
      <Image
        alt="Background Image"
        src={img}
        width={50}
        height={50}
        sizes="100vw"
        className={`${style.img_icon_svg_container}`}
      />
      <section>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </section>
    </div>
  )
}


const FeatureText = ({ title, description }) => {
  return (
    <div className={`${style.section_features_faqs}`}>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
}



const FeatureItem = (props) => {
  const componentsSelected = {
    image: <FeatureIMG {...props} />,
    image_svg: <FeatureICON_SVG {...props} />,
    text: <FeatureText {...props} />,
  };
  return componentsSelected[props.type] || null;
}

export default FeatureItem