import Image from 'next/image'
import style from "./style.module.scss";


const HeroLeftBottom = ({ data }) => {


  return (
    <section className={`${style.section_banner_lef_bottom_container}`
    }>
      <figure>
        <Image
          alt="Hero image"
          src={data?.image?.url}
          width={50}
          height={50}
          sizes="100vw"
          priori
        />
      </figure>

      <footer>
        <header>
          <h1 className='hidden md:flex' dangerouslySetInnerHTML={{ __html: data?.titulo }} />
          <h1 className='flex md:hidden' dangerouslySetInnerHTML={{ __html: data?.titulo }} />
        </header>
      </footer>
    </section>
  )
}


const HeroLeftCenter = ({ data, device, isMobile }) => {

  if (!data) { return null; }

  return (
    <section className={`${style.section_banner_lef_center_container}`
    }>

      <figure className=''>
        <Image
          alt="Hero image"
          src={data?.imagemobile?.url}
          width={500}
          height={422.69}
          sizes="(min-width: 768px) 50vw, 100vw"
          loading="lazy"
          className={`flex md:hidden`}
        />

        <Image
          alt="Hero image"
          src={data?.image?.url}
          width={1440}
          height={900.99}
          sizes="(max-width: 767px) 100vw, 50vw"
          priority
          className={`hidden md:flex`}
        />
      </figure>


      <footer className='container_long'>
        <header>
          <h2 dangerouslySetInnerHTML={{ __html: data?.titulo }} />
          <h1 dangerouslySetInnerHTML={{ __html: data?.descripcion }} />
        </header>
      </footer>
    </section>
  )
}

const HeroLeftCenterWithoutText = ({ data, device, isMobile }) => {

  return (
    <section className={`${style.section_banner_lef_center_without_text_container}`
    }>

      <figure className='flex sm:hidden'>
        <Image
          alt="Hero image"
          // src={data?.image?.url}
          src={`${process.env.NEXT_PUBLIC_RUTA_IMG}/header/header6_mob.svg`}
          width={50}
          height={50}
          sizes="100vw"
          priority
          className={``}
        />
      </figure>

      <figure className='hidden sm:flex'>
        <Image
          alt="Hero image"
          src={data?.image?.url}
          // src={`${process.env.NEXT_PUBLIC_RUTA_IMG}/header/header6.svg`}
          width={50}
          height={50}
          sizes="100vw"
          priority
          className={``}
        />
      </figure>
    </section>
  )
}


const Hero = (props) => {
  const componentsSelected = {
    left_bottom: <HeroLeftBottom {...props} />,
    left_center: <HeroLeftCenter {...props} />,
  };
  return componentsSelected[props.type] || null;
}

export default Hero