'use client'
import { NewsCard } from '@/components/shared';

const NewsCategoryList = ({ data, columns, category, ref }) => {

  if (!data) return null;

  const settingGrids = {
    1: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-10 gap-6',
    2: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 gap-6',
  };

  return (
    <>
      <article ref={ref} className={`${columns <= 3 ? settingGrids[1] : settingGrids[2]} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out`}>
        {data.map((item, idx) => (
          <div key={idx} >
            <NewsCard data={item} cat={item?.categoria_de_blog?.nombre} slug={category} totalNews={data.length} type={'list'} />
          </div>
        ))}
      </article >
    </>
  );
};

export default NewsCategoryList;
