'use client'
import { useState } from 'react'
import { SVGFaqClose, SVGFaqOpen } from '@/components/svg'
import style from "./style.module.scss";

const AccordionOpenItem = ({ index, pregunta, respuesta }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  // \  // overflow-hidden transition-all duration-300 ease-in-out max-h-0
  return (
    <>
      <li onClick={() => handleToggle(index)} >
        <button aria-expanded={activeIndex}
        >
          <h6 dangerouslySetInnerHTML={{ __html: pregunta }} />
          <div className={`${style.svg_container}`}>
            {activeIndex ? <SVGFaqClose /> : <SVGFaqOpen />}
          </div>
        </button>
        <div className={`text-left overflow-hidden transition-all
         ${activeIndex ? 'max-h-screen duration-[1300ms] ease-in-out' : 'duration-[400ms] ease-in-out max-h-0'} `}
        >
          <p dangerouslySetInnerHTML={{ __html: respuesta }} />
        </div>
      </li >

    </>
  )
}

export default AccordionOpenItem