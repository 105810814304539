import { FeatureItem } from '@/components/shared';
import style from "./style.module.scss";

const Feature = ({ data, type }) => {
  return (
    <>
      {data && (
        <div className={`${style.section_features}`}>
          {data.filter(item => item.status).map((item, idx) => (
            <FeatureItem key={item.id} idx={idx} type={type} {...item} />
          ))}
        </div>
      )}
    </>
  )
}

export default Feature