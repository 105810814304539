/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useState, useEffect } from 'react'
import Image from 'next/image';
import Link from 'next/link';
import style from "./style.module.scss";

const optionsDate = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};


function NewsMasonryItem({ content, paragraph = true, picture, cssText, cssFigure, cssImg }) {
  return (
    <article
      key={content.id}
      className={`group transition-all duration-500 ease-in-out transform  ${style.news_container} `}>

      <Link
        href={`/blog/${content?.categoria_de_blog?.slug}/${content?.slug}`}
        passHref
      >
        <figure className={` ${picture ? 'flex' : 'hidden'} w-full overflow-hidden ${cssFigure} rounded-t-lg `}>
          <Image
            alt="Image State"
            src={content?.banner?.formats?.small?.url}
            width={1796}
            height={1346}
            sizes="100vw"
            className={`${cssImg} rounded-t-lg transition-all duration-500 ease-in-out transform group-hover:scale-110`}
          />
        </figure>
      </Link>

      <div className={` ${style.summary} w-full px-4 py-3 flex flex-1 flex-col space-y-3 h-full `}>
        <div className={`${style.header_section}`}>
          <div className={` ${style.header_section_content} text-xana-txt-link `} >
            <Link
              href={`/blog/${content?.categoria_de_blog?.slug}`}
              passHref
            >
              <h5 className={`text-sm`}>
                {content?.categoria_de_blog?.nombre}
              </h5>
            </Link>
          </div>
          <Link
            href={`/blog/${content?.categoria_de_blog?.slug}/${content?.slug}`}
            passHref
          >
            <h3 className={` ${cssText} mt-1 `}>
              {content?.titulo}
            </h3>
            {paragraph && (
              <p className={` ${cssText} mt-2 `} dangerouslySetInnerHTML={{ __html: content?.resumen }} />
            )}
          </Link>
        </div>

        <div className={`${style.footer_section}`}>
          <h5>
            {new Date(content?.publishedAt).toLocaleDateString('es-ES', optionsDate)}
          </h5>
        </div>
      </div>
    </article>
  );
}

const NewsMasonry = (props) => {
  const [totalCategories, setTotalCategories] = useState(null)
  const [news, setNews] = useState(null)

  useEffect(() => {
    setTotalCategories(props?.data?.length)
    setNews(props?.data)
  }, [props?.data])

  if (!props.data) {
    return null
  }

  return (
    <>
      {totalCategories && totalCategories > 0 && (
        <div className={`${style.section_grid_3_container} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out`}>
          <div className={`
            ${style.news3_1_content}
            bg-xana-blue
          `}>
            <NewsMasonryItem
              content={news[0]}
              picture={true}
              cssFigure={`!h-[428px] md:!h-[450px]`}
              cssImg={`object-center md:object-top !h-[428px] md:!h-[450px]`}
              cssText={`line-clamp-3`}
              {...props}
            />
          </div>
          <div className={`
            ${style.news3_2_content}
            bg-xana-purple
          `}>
            <NewsMasonryItem
              content={news[1]}
              picture={true}
              paragraph={false}
              cssFigure={`!h-[254px] md:!h-[271px]`}
              cssImg={`object-top !h-[254px] md:!h-[271px]`}
              cssText={`line-clamp-2 md:line-clamp-3 lg:line-clamp-4`}
              {...props}
            />
          </div>
          <div className={`
            ${style.news3_2_content}
            bg-xana-yellow
          `}>
            <NewsMasonryItem
              content={news[2]}
              picture={false}
              paragraph={false}
              cssFigure={`!h-auto md:!h-full`}
              cssImg={`object-bottom !h-auto md:!h-full`}
              cssText={`line-clamp-2 md:line-clamp-3 lg:line-clamp-4`}
              {...props}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default NewsMasonry