'use client'
import { useState, useEffect } from 'react'
import Link from 'next/link';
import { Button, NewsCategoryGrid } from '@/components/shared'
import { getLastNewsBy } from '@/utils/news';
import useScreenResolution from '@/hooks/useScreenResolution';
import style from "./style.module.scss";

const NewsCategory = ({ data, list }) => {
  if (!data) { return null; }

  return (
    <>
      <div className={`${style.news_category} animate-fade animate-delay-200 animate-duration-[400ms] animate-ease-in-out`}>
        {Object.values(data) && Object.values(data).blogs > 0 && Object.values(data).map((category, index) => (
          <section key={index} className={`${style.section_news_category} `}>
            <header>
              <h2 dangerouslySetInnerHTML={{ __html: category?.nombre }} />
              <Link href={`/blog/${category.slug}`} passHref >
                <Button
                  cssClass={`!-mt-0 !justify-center !hidden md:!flex`}
                  type={'link_news'}
                  title={`Ver todos los artículos de ${category?.nombre}`}
                  icon={'<svg fill=\"currentColor\" viewBox=\"0 0 16 16\" > <path fillRule=\"evenodd\" d=\"M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z\" /> </svg>'}
                />
              </Link>
            </header>

            <footer>
              <NewsCategoryGrid data={category?.blogs} cat={category?.nombre} slug={category?.slug} />
              <div className={`mt-7 block md:hidden`}>
                <Link href={`/blog/${category.slug}`} passHref >
                  <Button
                    cssClass={`!-mt-0 !justify-center !w-full  `}
                    type={'link_news'}
                    title={`Ver todos los artículos de ${category?.nombre}`}
                    icon={'<svg fill=\"currentColor\" viewBox=\"0 0 16 16\" > <path fillRule=\"evenodd\" d=\"M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z\" /> </svg>'}
                  />
                </Link>
              </div>
            </footer>
          </section>
        ))}
      </div>
    </>
  )
}

export default NewsCategory