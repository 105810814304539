'use client'
import { useState } from 'react'
import { AccordionCloseItem } from '../..'
import style from "./style.module.scss";


const AccordionClose = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }
  return (
    <>
      <ul className={`${style.section_faqs}`}>
        {data?.items?.map((item, index) => (
          <AccordionCloseItem
            key={item.id}
            {...item}
            isOpen={activeIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
      </ul>
    </>
  )
}

export default AccordionClose