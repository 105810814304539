/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

/**
 * Breakpoint items to delete min 2 max 4
 * @param {Array} data - Array of items
 * @returns {Array} Items to show
 */
function useBreakpointItemsToDelete(data) {

  const [itemsToShow, setItemsToShow] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const breakpoint = getActiveBreakpoint(window.innerWidth);
      const activeItems = data.slice(0, breakpoint + 1);
      setItemsToShow(activeItems);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return itemsToShow;
}

// Helper function to determine active breakpoint
const getActiveBreakpoint = (width) => {
  if (width >= 1280) return 3;
  if (width >= 1024) return 2;
  if (width >= 768) return 1;
  return 0; // Default to smallest breakpoint
};

export default useBreakpointItemsToDelete;
