/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { TabsItem } from '@/components/shared'
import style from "./style.module.scss";

const Tabs = ({ data, showTabs, setActiveSelected, activeSelect }) => {
  const myRefTabs = useRef();
  const [activeTab, setActiveTab] = useState(null)
  const [filterData, setFilterData] = useState([])


  const handleTab = useCallback((tabItems) => {
    setActiveSelected(tabItems)
    setActiveTab(`tab${tabItems.nombre}`)
    setFilterData([tabItems])
  }, [setActiveSelected])

  useEffect(() => {
    setActiveTab(activeSelect !== null && `tab${activeSelect}`)
    setFilterData(getDataFilter(activeSelect))
  }, [activeSelect])

  const getDataFilter = useCallback((find) => {
    if (data && data.length > 0) {
      return data.filter(item => item.nombre === find)
    }
  }, [data])

  const tabItems = useMemo(() =>
    data?.filter(item => item.farmacias.length > 0).map((item, idx) => (
      <li
        key={item.id}
        className={`${activeTab === `tab${item.nombre}`
          ? 'border-b-[2px] py-1 -mb-[1px] !border-b-xana-txt-tab !text-xana-txt-tab  '
          : 'border-b-[2px] py-1 -mb-[1px] !border-b-transparent  text-xana-txt-text'}`
        }
        onClick={() => handleTab(item)}
      >
        {item.nombre}
      </li>
    )),
    [data, activeTab, handleTab]
  )

  return (
    <>
      <section ref={myRefTabs} className={`${style.section_tabs} `}>
        <nav className={`${showTabs ? '!block' : '!hidden'}`}>
          <ul>{tabItems}</ul>
        </nav>
        {activeTab && filterData.map((tab, idx) => (
          <TabsItem key={tab.id} idx={idx} showTabs={showTabs} {...tab} active={activeTab} />
        ))}
      </section>
    </>
  )
}

export default Tabs
