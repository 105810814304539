import Image from 'next/image'
import style from "./style.module.scss";

const ContentLeftRightItem = (props) => {
  const { titulo, descripcion, imagen, idx } = props;

  return (
    <>
      <article>
        <div className={`
          ${style.article_text_container}
          ${idx % 2 === 0
            ? 'order-first sm:order-first px-0 sm:pl-8 sm:pr-8 md:pl-0 md:pr-8'
            : 'order-first sm:order-last  px-0 sm:pl-8 sm:pr-8 md:pl-8 md:pr-0'
          }
        `}>
          <h3 dangerouslySetInnerHTML={{ __html: titulo }} />
          <p dangerouslySetInnerHTML={{ __html: descripcion }} />
        </div>

        {imagen && (
          <figure className={`
            ${style.article_figure_container}
            ${idx % 2 === 0
              ? 'justify-center'
              : 'justify-center '
            }`}
          >
            <Image
              alt="feature Image"
              src={imagen?.url}
              width={50}
              height={50}
              sizes="100vw"
              loading="lazy"
              className={`${idx % 2 === 0
                ? 'md:object-left'
                : 'md:object-right'
                }`}
            />
          </figure>
        )}
      </article >
    </>
  )
}

export default ContentLeftRightItem