/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useState, useEffect, useCallback } from 'react';

function useResponsiveAnimation(deviceType, isMobile, width, dataArray) {
  const [imgAnimate1, setImgAnimate1] = useState(false);
  const [imgAnimate2, setImgAnimate2] = useState(false);
  const [styleAnimate, setStyleAnimate] = useState(null);
  const [objectAnimate, setObjectAnimate] = useState(null);

  const handleMobileStyle = useCallback((width) => {
    if (dataArray !== null) {
      if (width >= 340 && width <= 459) {
        setStyleAnimate(dataArray.mobile.portrait);
      } else if (width >= 460 && width <= 960) {
        setStyleAnimate(dataArray.mobile.landscape);
      } else {
        setStyleAnimate(dataArray.mobile.portrait); // Default
      }
      setObjectAnimate(dataArray.mobile);
    }
  }, [dataArray]);

  const handleTabletStyle = useCallback((width) => {
    if (dataArray !== null) {
      if (width >= 540 && width <= 960) {
        setStyleAnimate(dataArray.tablet.portrait);
      } else if (width >= 961 && width <= 2024) {
        setStyleAnimate(dataArray.tablet.landscape);
      } else {
        setStyleAnimate(dataArray.tablet.portrait); // Default
      }
      setObjectAnimate(dataArray.tablet);
    }
  }, [dataArray]);

  const handleDesktopStyle = useCallback(() => {
    if (dataArray !== null) {
      setStyleAnimate(dataArray?.desktop);
      setObjectAnimate(dataArray?.desktop)
    }
  }, [dataArray]);


  useEffect(() => {
    const timeoutId1 = setTimeout(() => setImgAnimate1(true), 100);
    const timeoutId2 = setTimeout(() => setImgAnimate2(true), (deviceType !== 'mobile' || deviceType !== 'tablet') ? 500 : 200);

    if (deviceType !== null) {
      if (deviceType === undefined && !isMobile && width <= 720) {
        handleTabletStyle(width);
      }
      switch (true) {
        case (deviceType === 'smarttv' && isMobile):
          handleDesktopStyle();
          break;
        case (deviceType === undefined && !isMobile && width >= 720):
          handleDesktopStyle();
          break;
        case deviceType === 'mobile':
          handleMobileStyle(width);
          break;
        case deviceType === 'tablet':
          handleTabletStyle(width);
          break;
        default:
          break;
      }
    }
    return () => clearTimeout(timeoutId1) && clearTimeout(timeoutId2);
  }, [deviceType, isMobile, width, handleMobileStyle, handleTabletStyle]);

  return { imgAnimate1, imgAnimate2, styleAnimate, objectAnimate };
}

export default useResponsiveAnimation;