'use client'
import { useRef } from 'react'
import { AccordionOpenItem } from '../..'
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import style from "./style.module.scss";


const AccordionOpen = ({ data }) => {
  if (!data) { return null; }

  return (
    <ul className={`${style.section_faqs}`}>
      {data?.preguntasFrecuentes.map((item, index) => (
        <AccordionOpenItem
          key={item.id}
          index={index + 1}
          {...item}
        />
      ))}
    </ul>
  )
}

export default AccordionOpen